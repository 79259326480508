<template>
    <el-container>
        <el-container>
            <!-- 中间内容 -->
            <el-main>
              <h2 class="depTitle" v-if="departmentName=='人事科'">科室人员信息管理</h2>
              <h2 class="depTitle" v-else>科室管理</h2>
                <div class="RightDiv">
                    <el-form :model="searchForm" class="searForm">
                        <div class="table-title">
                            <div>
                                <el-input v-model="searchForm.nameLike" placeholder="科室名称" clearable prefix-icon="el-icon-search" @keyup.enter.native="searchList" @blur="searchList" @clear="searchList">
                                </el-input>
                            </div>
                            <div class="index-add" v-if="departmentName!=='人事科'">
                                <el-button type="primary" @click="depaddKeshi">添加科室</el-button>
                            </div>
                        </div>
                    </el-form>
                </div>
                <el-table :data="tableData" border center>
                    <el-table-column type="index" label="序号" width="55" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="科室名称" width="350" align="center">
                    </el-table-column>
                    <el-table-column prop="updateDate" label="更新时间" align="center">
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template style="display: flex" slot-scope="scope">
                            <el-tooltip  v-if="departmentName!=='人事科'" class="item" effect="dark" content="基础科室" placement="top">
                              <el-button size="mini" type="primary" @click="basiKeshi(scope.row)" icon="el-icon-menu"></el-button>
                            </el-tooltip>
                            <el-tooltip  v-if="departmentName!=='人事科'" class="item" effect="dark" content="医嘱小类" placement="top">
                              <el-button size="mini" type="primary" @click="meadSub(scope.row)"><i class="el-icon-notebook-1"></i></el-button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="科室用户" placement="top">
                              <el-button size="mini" type="primary" @click="setKeshi(scope.row)"><i class="el-icon-user-solid"></i></el-button>
                            </el-tooltip>
                            <el-tooltip  v-if="departmentName!=='人事科'" class="item" effect="dark" content="修改科室" placement="top">
                              <el-button size="mini" type="primary" @click="editKeshi(scope.row)"><i class="el-icon-edit"></i></el-button>
                            </el-tooltip>
                            <el-button  v-if="departmentName!=='人事科'" size="mini" type="danger" @click="deleteKeshi(scope.row)"><i class="el-icon-delete-solid"></i></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
                </el-pagination>
            </el-main>
        </el-container>
        <!-- 点击添加科室弹出框 -->
        <el-dialog title="添加科室" :visible.sync="AdddialogVisible" width="400px" :before-close="AdddialogVisibleClose">
            <el-form :model="AddDateModel" ref="AddDateRef" :rules="AddDateRules" label-width="170px">
                <el-form-item label="科室名称：" prop="name">
                    <el-input v-model="AddDateModel.name" placeholder="请填写科室"></el-input>
                </el-form-item>
<!--              <el-form-item label="科室类型：" prop="type">-->
<!--                <el-select-->
<!--                    v-model="AddDateModel.type"-->
<!--                    placeholder="请选择科室类型"-->
<!--                    clearable-->
<!--                    @change="deptypeValueChange(AddDateModel.type)"-->
<!--                >-->
<!--                  <el-option-->
<!--                      v-for="item in depTypeoptions"-->
<!--                      :key="item.value"-->
<!--                      :label="item.label"-->
<!--                      :value="item.value"-->
<!--                  >-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
              <el-form-item label="是否计算标化工作量：" prop="whecat">
                <el-radio-group v-model="AddDateModel.whecat" @change="changeTheme(AddDateModel.whecat)">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>

              <!--选是的话显示-->
              <el-form-item label="标化系数：" prop="coefficient" v-if="coeShow">
                <el-input v-model="AddDateModel.coefficient" placeholder="请填写标化系数"></el-input>
              </el-form-item>

              <el-form-item class="depar-dia">
                    <div class="btnCen">
                        <el-button v-if="addDepClick" type="primary" :loading="addDepClickKing"  @click="addKeshi">添加</el-button>
                        <el-button v-else type="info" :loading="addDepClickKing"></el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 点击修改科室弹出框 -->
        <el-dialog title="修改科室" :visible.sync="EditdialogVisible" width="400px" :before-close="EditdialogVisibleClose">
            <el-form :model="EditDateModel" ref="EditDateRef" :rules="EditDateRules" label-width="170px">
                <el-form-item label="科室名称：" prop="name">
                    <el-input v-model="EditDateModel.name" placeholder="请填写科室名称"></el-input>
                </el-form-item>
<!--              <el-form-item label="科室类型：" prop="type">-->
<!--                <el-select-->
<!--                    v-model="EditDateModel.type"-->
<!--                    placeholder="请选择科室类型"-->
<!--                    clearable-->
<!--                    @change="depedittypeValueChange(EditDateModel.type)"-->
<!--                >-->
<!--                  <el-option-->
<!--                      v-for="item in depTypeoptions"-->
<!--                      :key="item.value"-->
<!--                      :label="item.label"-->
<!--                      :value="item.value"-->
<!--                  >-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
              <el-form-item label="是否计算标化工作量：" prop="whecat">
                <el-radio-group v-model="EditDateModel.whecat" @change="changeEditTheme(EditDateModel.whecat)">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>

              <!--选是的话显示-->
              <el-form-item label="标化系数：" prop="coefficient" v-if="coeShow">
                <el-input v-model="EditDateModel.coefficient" placeholder="请填写标化系数"></el-input>
              </el-form-item>

                <el-form-item class="depar-dia">
                    <div class="btnCen">
                        <el-button v-if="editDepClick" type="primary" :loading="editDepClickKing" @click="editdialogKeshi">修改</el-button>
                        <el-button v-else type="info" :loading="editDepClickKing"></el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
      <el-dialog :title="meadDep+'医嘱小类'" :visible.sync="meadSubVisible" top="1vh" width="90%" :before-close="meadSubVisibleClose">
          <Mead-subca :meadDepId="meadDepId"></Mead-subca>
      </el-dialog>
        <!-- 点击科室用户管理 -->
        <el-dialog title="科室用户管理" top="2vh" :visible.sync="SetdialogVisible" width="960px" :before-close="SetdialogVisibleClose">
            <div>
                <div class="AddUserDiv">
                    <el-button class="AddUser" type="primary" @click="AddUser">添加科室用户</el-button>
                </div>
                <el-table :data="UsertableData" style="width: 95%; margin: 0 auto" border center>
                    <el-table-column type="index" label="序号" width="55" align="center">
                    </el-table-column>
                    <el-table-column prop="nickName" label="姓名" align="center">
                    </el-table-column>
                    <el-table-column prop="phonenumber" label="手机号" align="center">
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="200">
                        <template style="display: flex" slot-scope="scope">
                            <el-button size="mini" type="primary" @click="EditUser(scope.row)"><i class="el-icon-edit"></i></el-button>
                            <el-button size="mini" type="danger" @click="DeleteUser(scope.row)"><i class="el-icon-delete-solid"></i></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background @current-change="handleCurrentUsertabChange" :current-page="pageUserNum" :page-size="pageUserSize" layout="  prev, pager, next" :total="totalUser">
                </el-pagination>
            </div>
        </el-dialog>
        <!-- 添加科室用户 -->
        <el-dialog title="添加科室用户" :visible.sync="AdduserdialogVisible" width="400px" :before-close="AdduserdialogVisibleClose">
            <el-form :model="AddUserModel" ref="AddUserRef" :rules="AddUserRules" :inline="true" label-width="100px">
                <el-form-item label="姓名：" prop="nickName">
                    <el-input v-model="AddUserModel.nickName" placeholder="请填写姓名"></el-input>
                </el-form-item>
                <el-form-item label="手机号：" prop="phonenumber">
                    <el-input type="phone" v-model="AddUserModel.phonenumber" placeholder="请填写手机号"></el-input>
                </el-form-item>
                <el-form-item label="数据维护：" class="depart-addnum">
                    <el-switch v-model="AddUserModel.dataAuth" active-value="0" inactive-value="1" active-color="#409EFF" inactive-color="#DCDFE6">
                    </el-switch>
                </el-form-item>
<!--                <el-form-item label="绩效权限：" class="depart-addnum">-->
<!--                    <el-switch v-model="AddUserModel.performanceAuth" active-value="0" inactive-value="1" active-color="#409EFF" inactive-color="#DCDFE6">-->
<!--                    </el-switch>-->
<!--                </el-form-item>-->
                <el-form-item class="depar-dia">
                    <div class="btnCen">
                        <el-button v-if="adduserClick" type="primary" :loading="adduserLoading"   @click="adduserkeshi">添加</el-button>
                        <el-button v-else type="info" :loading="adduserLoading"></el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 修改科室用户 -->
        <el-dialog title="修改科室用户" :visible.sync="EdituserdialogVisible" width="400px" :before-close="EdituserdialogVisibleClose">
            <el-form :model="EditUserModel" ref="EditUserRef" :rules="EditUserRules" :inline="true" label-width="100px">
                <el-form-item label="姓名：" prop="nickName">
                    <el-input v-model="EditUserModel.nickName" placeholder="请填写姓名"></el-input>
                </el-form-item>
                <el-form-item label="手机号：" prop="phonenumber">
                    <el-input type="phone" v-model="EditUserModel.phonenumber" placeholder="请填写手机号"></el-input>
                </el-form-item>
                <el-form-item label="数据维护：" class="depart-addnum">
                    <el-switch v-model="EditUserModel.dataAuth" active-value="0" inactive-value="1" active-color="#409EFF" inactive-color="#DCDFE6">
                    </el-switch>
                </el-form-item>
<!--                <el-form-item label="绩效权限：">-->
<!--                    <el-switch v-model="EditUserModel.performanceAuth" active-value="0" inactive-value="1" active-color="#409EFF" inactive-color="#DCDFE6">-->
<!--                    </el-switch>-->
<!--                </el-form-item>-->
                <el-form-item class="depar-dia">
                    <div class="btnCen">
                        <el-button v-if="editUserClick" type="primary" :loading="editUserLoading" @click="edituserkeshi">修改</el-button>
                        <el-button v-else type="info" :loading="editUserLoading"></el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
<!--      基础科室-->
      <el-dialog :title="addBasicTit" :visible.sync="AddbasicdialogVisible" width="400px" :before-close="AddbasicdialogVisibleClose">
        <el-form :model="AddBasicModel" ref="AddBasicRef" :rules="AddBasicRules" :inline="true" label-width="100px">
          <el-form-item label="科室ID：" prop="departId">
            <el-input v-model="AddBasicModel.departId" placeholder="请填写科室ID"></el-input>
          </el-form-item>
          <el-form-item label="科室名称：" prop="deparName">
            <el-input v-model="AddBasicModel.deparName" placeholder="请填写科室名称"></el-input>
          </el-form-item>

          <el-form-item class="depar-dia">
            <div class="btnCen">
              <el-button v-if="addbasicClick" type="primary" :loading="addbasicLoading"   @click="addbasickeshi">{{ addBtn }}</el-button>
              <el-button v-else type="info" :loading="addbasicLoading"></el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
      <!--   基础科室-->
      <el-dialog title="基础科室管理" top="2vh" :visible.sync="basicdialogVisible" width="960px" :before-close="basicdialogVisibleClose">
        <div>
          <div class="AddUserDiv">
            <el-button class="AddUser" type="primary" @click="AddBasic">添加基础科室</el-button>
          </div>
          <el-table :data="basictableData" style="width: 95%; margin: 0 auto" border center>
            <el-table-column type="index" label="序号" width="55" align="center">
            </el-table-column>
            <el-table-column prop="departmentName" label="基础科室名称" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center" width="200">
              <template style="display: flex" slot-scope="scope">
                <el-button size="mini" type="primary" @click="EditBasic(scope.row)"><i class="el-icon-edit"></i></el-button>
                <el-button size="mini" type="danger" @click="DeleteBasic(scope.row)"><i class="el-icon-delete-solid"></i></el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background @current-change="handleCurrentUserChange" :current-page="pageBasiNum" :page-size="pageBasiSize" layout="  prev, pager, next" :total="basictotal">
          </el-pagination>
        </div>
      </el-dialog>
    </el-container>
</template>

<script>
import _qs from "qs";
import MeadSubca from "../Hospital/meadSubca.vue"
export default {
  components: { MeadSubca },
    data() {
        var checkAge = (rule, value, callback) => {
            if (!value && String(value) !== "0") {
                return callback(new Error("不能为空"));
            }
            setTimeout(() => {
                if (!/^1[3|4|5|7|8][0-9]\d{8}$/.test(value)) {
                    callback(new Error("请输入正确的手机号"));
                } else {
                    callback();
                }
            }, 100);
        };
        return {
          basicdialogVisible:false,
          depTypeoptions:[{
            value:'0',
            label:"临床医疗"
          },{
            value:'1',
            label:"医疗技术"
          },{
            value:'2',
            label:"医疗辅助"
          },{
            value:'3',
            label:"行政后勤"
          }
          ],
          meadDepId:'',
          meadDep:'',
          meadSubVisible:false,
            // 添加科室的添加按钮
            addDepClick: true,
            addDepClickKing: false,
            // 添加用户的添加按钮
            adduserLoading: false,
            // 查询用的业务名称
            searchForm: {
                nameLike: "",
            },
            // 添加科室用户的添加
            adduserClick: true,
            // 修改科室用户的修改按钮
            editUserClick: true,
            editUserLoading: false,
            addbasicLoading:false,
            addbasicClick:true,
            // 修改科室用户
            EditUserModel: {
                // 名称
                nickName: "",
                // 手机号
                phonenumber: "",
                // 是否有数据维护 0 选择 1 不选
                dataAuth: "",
                // 是否有绩效权限 0 选择 1 不选
                performanceAuth: "",
            },
            EditUserRules: {
                nickName: [
                    {
                        required: true,
                        message: "请输入科室用户名称",
                        trigger: "blur",
                    },
                ],
                phonenumber: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
            },
            // 添加科室用户
            AddUserModel: {
                // 名称
                nickName: "",
                // 手机号
                phonenumber: "",
                // 是否有数据维护 0 选择 1 不选
                dataAuth: "",
                // 是否有绩效权限 0 选择 1 不选
                performanceAuth: "",
            },
          AddBasicModel:{
            departId:"",
            deparName:""
          },
          AddBasicRules: {
            departId: [
              {
                required: true,
                message: "请输入科室ID",
                trigger: "blur",
              },
            ],
            deparName: [
              {
                required: true,
                message: "请输入科室名称",
                trigger: "blur",
              },
            ],
          },
            AddUserRules: {
                nickName: [
                    {
                        required: true,
                        message: "请输入科室用户名称",
                        trigger: "blur",
                    },
                ],
                phonenumber: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
            },
          coeShow:false,
            // 添加科室
            AddDateModel: {
                // 科室名称
                name: "",
              type:"",
              whecat:"",
              coefficient:""
            },
            AddDateRules: {
              whecat: [
                {
                  required: true,
                  message: "请选择是否计算标化工作量",
                  trigger: "change",
                },
              ],
                name: [
                    {
                        required: true,
                        message: "请输入科室名称",
                        trigger: "blur",
                    },
                ],
              coefficient: [
                {
                  required: true,
                  message: "请输入标化系数",
                  trigger: "blur",
                },
              ],
              type: [
                {
                  required: true,
                  message: "请输入科室类型",
                  trigger: "change",
                },
              ],
            },
            // 修改科室
            EditDateModel: {
                // 科室名称
                name: "",
              type:"",
              whecat:"",
              coefficient:""
            },
            EditDateRules: {
                name: [
                    {
                        required: true,
                        message: "请输入科室名称",
                        trigger: "blur",
                    },
                ],
              coefficient: [
                {
                  required: true,
                  message: "请输入标化系数",
                  trigger: "blur",
                },
              ],
              type: [
                {
                  required: true,
                  message: "请输入科室类型",
                  trigger: "change",
                },
              ],
              whecat: [
                {
                  required: true,
                  message: "请选择是否计算标化工作量",
                  trigger: "change",
                },
              ],
            },
            // 数据维护开关
            phonenumber: 1,
            // 点击添加科室弹出框
            AdddialogVisible: false,
            // 修改科室弹出框
            EditdialogVisible: false,
            // 科室用户管理
            SetdialogVisible: false,
            // 添加科室用户
            AdduserdialogVisible: false,
          // 添加基础科室
          AddbasicdialogVisible:false,
            // 修改科室用户
            EdituserdialogVisible: false,
            // 科室管理的分页
            // 当前页数
            pageNum: 1,
            // 每页显示条数
            pageSize: 10,
            // 总数
            total: 1,
            // 科室用户管理的分页
            // 当前页数
            pageUserNum: 1,
            // 每页显示条数
            pageUserSize: 10,
            totalUser: 1,
          basictotal:1,
            pageBasiNum:1,
            pageBasiSize:10,
            // 表格数据
            tableData: [],
            // 科室用户管理表格
            UsertableData: [],
            // 医院id
            hospitalId: window.sessionStorage.getItem("hospitalId"),
            // 科室id
          departmentId: window.sessionStorage.getItem("departmentId"),
          departmentName: window.sessionStorage.getItem("departmentName"),
            // 科室用户点击修改的id
            userId: "",
            keshiid: "",
            // 科室id
            keshiUserid: "",
            // 修改科室的修改按钮
            editDepClick: true,
            editDepClickKing: false,
          basictableData:[],//基础科室
          keshiBasicid:'',
          addBasicTit:"",
          addBtn:"",
          basicId:""
        };
    },
    created() {
        this.getDepartmentList();
    },
    methods: {
      changeEditTheme(val){
        // console.log(this.EditDateModel.whecat)
        this.EditDateModel.coefficient=''
        if(val=='1'){
          this.coeShow=true
        }else{
          this.coeShow=false
        }
      },
      // 是否计算标化工作量
      changeTheme(val){
        // console.log(this.AddDateModel.whecat)
        this.AddDateModel.coefficient=''
        if(val=='1'){
          this.coeShow=true
        }else{
          this.coeShow=false
        }
      },
      basicdialogVisibleClose(){
          this.basicdialogVisible=false
          this.pageBasiNum=1
      },
        // 搜索框
        searchList() {
            this.getDepartmentList();
        },
        // 科室管理分页
        handleCurrentChange(val) {
            this.pageNum = val;
            // console.log(`当前页: ${val}`);
            this.getDepartmentList();
        },
      // 科室用户管理分页
      handleCurrentUsertabChange(val){
        this.pageUserNum = val;
        // console.log(`当前页: ${val}`);
        let keshiUseridRow = { id: this.keshiUserid };
        this.setKeshi(keshiUseridRow);
      },
        // 基础科室分页
        handleCurrentUserChange(val) {
            this.pageBasiNum = val;
            // console.log(`当前页: ${val}`);
          let keshiRow = { id: this.keshiBasicid };
          this.basiKeshi(keshiRow)
        },
        // 点击添加科室
        depaddKeshi() {
            this.AdddialogVisible = true;
        },
        // 点击添加科室的时候取消正则
        AdddialogVisibleClose() {
            this.AdddialogVisible = false;
            this.coeShow=false
            this.$refs.AddDateRef.resetFields();
        },
        // 点击操作的修改科室时候取消正则
        EditdialogVisibleClose() {
            this.EditdialogVisible = false;
            this.coeShow=false
            this.$refs.EditDateRef.resetFields();
        },
        // 点击添加科室用户时候取消正则
        AdduserdialogVisibleClose() {
            this.adduserLoading = false;
            this.AdduserdialogVisible = false;
            this.$refs.AddUserRef.resetFields();
        },
      AddbasicdialogVisibleClose() {
        this.AddbasicdialogVisible = false;
        this.basicId=''
        this.AddBasicModel={
          departId:"",
              deparName:""
        }
      },
        // 点击科室用户管理的修改时候取消正则
        EdituserdialogVisibleClose() {
            this.editUserLoading = false;
            this.EdituserdialogVisible = false;
            this.$refs.EditUserRef.resetFields();
        },
        // 点击操作添加科室的添加
        addKeshi() {
            this.$refs.AddDateRef.validate((valid) => {
                if (valid) {
                    this.addDepClick = false;
                    this.addDepClickKing = true;
                    let data = _qs.stringify({
                        hospitalId: this.hospitalId,
                        name: this.AddDateModel.name,
                        isWorkload:this.AddDateModel.whecat,
                      coefficient:this.AddDateModel.coefficient,
                        // type:this.AddDateModel.type
                    });
                    this.addDepartment(data);
                }
            });
        },
        // 点击操作删除
        async deleteKeshi(row) {
            const confirmResult = await this.$confirm("确认删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).catch((err) => err);

            if (confirmResult !== "confirm") {
                return this.$message.info("已经取消删除");
            }
            let data = _qs.stringify({
                ids: row.id,
            });
            let { data: res } = await this.$axios.removeDepartment(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: "科室中存在用户，不能删除。",
                    type: "error",
                });
            } else if (res.code == 200) {
                this.$message({
                    message: "删除成功",
                    type: "success",
                });
                this.getDepartmentList();
            }
        },
        // 点击操作修改科室的添加
        editKeshi(row) {
          if(row.isWorkload==1){
            this.coeShow=true
          }else{
            this.coeShow=false
          }
          this.keshiid = row.id;
          this.EditDateModel.name = row.name;
          this.EditDateModel.type = row.type+'';
          this.EditDateModel.whecat= row.isWorkload+'';
          this.EditDateModel.coefficient= row.coefficient;
          this.EditdialogVisible = true;

        },
        SetdialogVisibleClose() {
            this.pageUserNum = 1;
            this.SetdialogVisible = false;
        },
        // 基础科室
       async basiKeshi(row){
         this.keshiBasicid = row.id;
          this.basicdialogVisible = true;
          let data = {
            departmentId: this.keshiBasicid, //科室id
            pageNum: this.pageBasiNum, //页数
            pageSize: this.pageUserSize, //每页显示条数
          };
          let { data: res } = await this.$axios.pageList(data);
          // console.log(res);
          if (res.code == 401) {
            this.$router.push("/login");
          } else if (res.code == 200) {
            this.basictableData = res.rows;
            this.basictotal = res.total;
          }
        },
      meadSubVisibleClose(){
          this.meadSubVisible=false
          this.meadDep=''
          this.meadDepId=''
      },
      // 医嘱小类
      async meadSub(row){
        this.meadSubVisible=true
        // console.log(row.id)
        this.meadDep=row.name
        this.meadDepId=row.id
      },
        // 点击操作的科室用户管理
        async setKeshi(row) {
            this.keshiUserid = row.id;
            this.SetdialogVisible = true;
            let data = {
                hospitalId: this.hospitalId, //医院id
                departmentId: row.id, //科室id
                pageNum: this.pageUserNum, //页数
                pageSize: this.pageUserSize, //每页显示条数
            };
            let { data: res } = await this.$axios.getDepartmentUserList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.UsertableData = res.rows;
                this.totalUser = res.total;
            }
        },
        // 点击修改科室用户管理的添加
        editdialogKeshi() {
            this.$refs.EditDateRef.validate((valid) => {
                if (!valid) return;
                this.editDepClick = false;
                this.editDepClickKing = true;
                let data = _qs.stringify({
                    name: this.EditDateModel.name, //科室名称
                    hospitalId: this.hospitalId, //医院id
                    id: this.keshiid, //科室id
                    // type:this.EditDateModel.type,
                  isWorkload:this.EditDateModel.whecat,
                  coefficient:this.EditDateModel.coefficient
                });
                this.addDepartment(data);
            });
        },
        // 添加或修改科室信息接口
        async addDepartment(data) {
            let res = await this.$axios.addDepartment(data);
            // console.log(res);
            this.addDepClick = true;
            this.addDepClickKing = false;
            this.editDepClick = true;
            this.editDepClickKing = false;
            if (res.data.code == 401) {
                this.$router.push("/login");
            }else if (res.data.code == 200) {
                this.EditdialogVisible = false;
                this.AdddialogVisible = false;
                // 添加科室
                this.AddDateModel = {
                    // 科室名称
                    name: "",
                };
                this.$message({
                    message: res.data.msg,
                    type: "success",
                });
                this.getDepartmentList();
              this.coeShow=false
            } else{
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
        },
        // 点击添加科室用户
        AddUser() {
            this.AdduserdialogVisible = true;
        },
        // 点击添加基础科室
      AddBasic(){
        this.AddbasicdialogVisible = true;
        this.addBasicTit='添加基础科室'
        this.addBtn='添加'
      },
        // 修改科室用户
        EditUser(row) {
            this.userId = row.userId;
            this.EditUserModel = row;
            this.EdituserdialogVisible = true;
        },
        // 修改基础科室
      EditBasic(row){
        this.basicId = row.id;
        this.AddBasicModel.departId=row.basicsDepartmentId
        this.AddBasicModel.deparName=row.departmentName
        this.AddbasicdialogVisible = true;
        this.addBasicTit='修改基础科室'
        this.addBtn='修改'
      },
        // 删除科室用户
        async DeleteUser(row) {
            const confirmResult = await this.$confirm("确认删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).catch((err) => err);

            if (confirmResult !== "confirm") {
                return this.$message.info("已经取消删除");
            }
            let data = _qs.stringify({
                userIds: row.userId,
            });
            let { data: res } = await this.$axios.removeDepartmentUser(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            } else if (res.code == 200) {
                this.$message({
                    message: res.msg,
                    type: "success",
                });
                let keshiUseridRow = { id: this.keshiUserid };
                this.setKeshi(keshiUseridRow);
            }
        },
      // 删除基础科室
      async DeleteBasic(row){
        const confirmResult = await this.$confirm("确认删除？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch((err) => err);

        if (confirmResult !== "confirm") {
          return this.$message.info("已经取消删除");
        }
        let data = _qs.stringify({
          id: row.id,
        });
        let { data: res } = await this.$axios.deletBasicDepartment(data);
        // console.log(res);
        if (res.code == 401) {
          this.$router.push("/login");
        } else if (res.code == 500) {
          this.$message({
            message: res.msg,
            type: "error",
          });
        } else if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          let keshiRow = { id: this.keshiBasicid };
          this.basiKeshi(keshiRow)
        }
      },
        //点击添加科室用户的添加
        adduserkeshi() {
            this.$refs.AddUserRef.validate(async (valid) => {
                if (!valid) return;
                this.adduserClick = false;
                this.adduserLoading = true;
                let data = _qs.stringify({
                    nickName: this.AddUserModel.nickName, //名称
                    phonenumber: this.AddUserModel.phonenumber, //手机号
                    dataAuth: this.AddUserModel.dataAuth, //是否有数据维护 0 选择 1 不选
                    performanceAuth: this.AddUserModel.performanceAuth, //是否有绩效权限 0 选择 1 不选
                    departmentId: this.keshiUserid, //科室id
                });
                this.addAndEdit(data);
            });
        },
        // 点击添加基础科室的添加
      addbasickeshi() {
        if(this.addBtn=='添加'){
          this.$refs.AddBasicRef.validate(async (valid) => {
            if (!valid) return;
            this.addbasicClick = false;
            this.addbasicLoading = true;
            let data = _qs.stringify({
              hospitalId:this.hospitalId,//医院id
              departmentId: this.keshiBasicid, //科室id
              basicsDepartmentId:this.AddBasicModel.departId,//基础科室id
              departmentName:this.AddBasicModel.deparName,//基础科室名称
            });
            let res = await this.$axios.insertBasicDepartment(data);
            // console.log(res);
            this.addbasicClick = true;
            this.addbasicLoading = false;
            if (res.data.code == 500) {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            } else if (res.data.code == 401) {
              this.$router.push("/login");
            } else if (res.status == 200) {
              this.$message({
                message:res.data.msg,
                type: "success",
              });
              this.AddbasicdialogVisible=false
              let keshiRow = { id: this.keshiBasicid };
              this.basiKeshi(keshiRow)
              this.AddbasicdialogVisibleClose()
            }
          });
        }else if(this.addBtn=='修改'){
          this.$refs.AddBasicRef.validate(async (valid) => {
            if (!valid) return;
            this.addbasicClick = false;
            this.addbasicLoading = true;
            let data = _qs.stringify({
              id: this.basicId, //科室id
              basicsDepartmentId:this.AddBasicModel.departId,//基础科室id
              departmentName:this.AddBasicModel.deparName,//基础科室名称
            });
            let res = await this.$axios.updateBasicDepartment(data);
            // console.log(res);
            this.addbasicClick = true;
            this.addbasicLoading = false;
            if (res.data.code == 500) {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            } else if (res.data.code == 401) {
              this.$router.push("/login");
            } else if (res.status == 200) {
              this.$message({
                message:res.data.msg,
                type: "success",
              });
              this.AddbasicdialogVisible=false
              let keshiRow = { id: this.keshiBasicid };
              this.basiKeshi(keshiRow)
              this.AddbasicdialogVisibleClose()
            }
          });
        }

      },
        //点击修改科室用户的添加
        edituserkeshi() {
            this.$refs.EditUserRef.validate((valid) => {
                if (!valid) return;
                this.editUserClick = false;
                this.editUserLoading = true;
                let data = _qs.stringify({
                    nickName: this.EditUserModel.nickName, //名称
                    phonenumber: this.EditUserModel.phonenumber, //手机号
                    dataAuth: this.EditUserModel.dataAuth, //是否有数据维护 0 选择 1 不选
                    performanceAuth: this.EditUserModel.performanceAuth, //是否有绩效权限 0 选择 1 不选
                    userId: this.userId, //用户id
                    departmentId: this.keshiUserid, //科室id
                });
                this.addAndEdit(data);
            });
        },
        // 添加或修改科室用户接口
        async addAndEdit(data) {
            let res = await this.$axios.addAndEdit(data);
            // console.log(res);
            this.adduserClick = true;
            this.adduserLoading = false;
            this.editUserClick = true;
            this.editUserLoading = false;
            if (res.data.code == 500) {
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            } else if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.status == 200) {
                this.$message({
                    message: "添加/修改科室用户成功",
                    type: "success",
                });
                let keshiUseridRow = { id: this.keshiUserid };
                this.setKeshi(keshiUseridRow);
                this.AdduserdialogVisible = false;
                this.EdituserdialogVisible = false;
                this.AddUserModel = {};
            }
        },
        // 分页显示科室管理数据
        async getDepartmentList() {
            let data = {
                hospitalId: this.hospitalId, //登录后返回的 医院id
                nameLike: this.searchForm.nameLike, //查询用的 科室名称
                pageNum: this.pageNum, //页数
                pageSize: this.pageSize, //每页显示条数
                orderByColumn: "updateDate", //排序参数 updateDate 更新时间
                isAsc: "desc", //asc 升序 desc降序
            };
            let { data: res } = await this.$axios.getDepartmentList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.tableData = res.rows;
                this.total = res.total;
            }
        },
    },
};
</script>

<style lang="less" scoped>
.RightDiv {
    margin: 0 auto;
}
.searForm {
    float: right;
}
.depTitle {
    display: flex;
    margin: 0 auto;
    margin-bottom: 20px;
}
.depart-addnum {
    width: 100%;
}

.AddUser {
    float: right;
    margin-bottom: 10px;
}

.AddUserDiv {
    width: 95%;
    margin: 0 auto;
}

/deep/ .depar-dia .el-form-item__content {
    margin-left: 0px !important;
}

.btnCen {
    .el-button {
        width: 150px;
        height: 40px;
    }
}

.depar-dia {
    width: 100%;
}

.collapse-btn {
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    background: rgb(134 140 146);
}
/deep/ .el-radio-button__inner {
    width: 100px;
}
.el-pagination {
    margin-top: 20px;
}
.el-main {
    .table-title {
        display: flex;
        margin-bottom: 10px;
        .index-add {
            margin-left: 20px;
        }
    }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}
</style>
