<template>
  <div>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
<!--        <h2 class="depTitle">医嘱小类</h2>-->
        <div class="hosrow">
          <div></div>
          <div>
            <el-button type="primary" @click="addMeadSub">添加医嘱小类</el-button>
          </div>
        </div>
        <el-table :data="tableData" border center>
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column prop="name" label="名称" align="center">
          </el-table-column>
          <el-table-column prop="score" label="分值" align="center">
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center" width="250">
            <template style="display: flex" slot-scope="scope">
              <el-button size="mini" type="primary" @click="editMead(scope.row)"><i class="el-icon-edit"></i></el-button>
              <el-button size="mini" type="danger" @click="deleteMead(scope.row)"><i class="el-icon-delete-solid"></i></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
        </el-pagination>
      </el-main>
    </el-container>

    <!-- 点击添加医嘱小类弹出框 -->
    <el-dialog :title="medTit" append-to-body :visible.sync="AdddialogVisible" width="360px" :before-close="AdddialogVisibleClose">
      <el-form :model="AddDateModel" ref="AddDateRef" :rules="AddDateRules" label-width="100px">
        <el-form-item label="小类名称：" prop="name">
          <el-input v-model="AddDateModel.name" placeholder="请填写小类名称"></el-input>
        </el-form-item>
        <el-form-item label="小类分值：" prop="score">
          <el-input v-model="AddDateModel.score" placeholder="请填写小类分值"></el-input>
        </el-form-item>
        <el-form-item class="depar-dia">
          <div class="btnCen">
            <el-button v-if="addDepClick" type="primary" :loading="addDepClickKing"  @click="addMead">{{ titBtn }}</el-button>
            <el-button v-else type="info" :loading="addDepClickKing"></el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import _qs from "qs";

export default {
  props:['meadDepId'],
  data() {
    let validateUser = async (rule, value, callback) => {
      //验证器
      if (value) {
        if (this.medTit == "修改医嘱小类") {
          callback();
        } else {
          let data = {
            name: value,
            departmentId: this.meadDepId,
          };
          let { data: res } = await this.$axios.depIsExist(data);
          // console.log(res);
          if (res.code == 200) {
              callback();
          } else {
            callback(new Error(res.msg));
          }
        }
      } else {
        callback(new Error("小类名称不能为空"));
      }
    };
    return {
      titBtn:'添加',
      medTit:'添加医嘱小类',
// 当前页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      // 表格数据
      tableData: [],
      total:1,
      AddDateModel: {
        name: "",
        score:""
      },
      AdddialogVisible:false,
      AddDateRules: {
        name: [
          { required: true, validator: validateUser, trigger: "blur" },
        ],
        score: [
          {
            required: true,
            message: "请输入小类分值",
            trigger: "blur",
          },
        ],
      },
      addDepClick: true,
      addDepClickKing: false,
      // 医院id
      hospitalId: window.sessionStorage.getItem("hospitalId"),
      updId:'',//修改的id
    };
  },
  components: {
  },
  watch: {
    meadDepId: {
      handler(newName, oldName) {
        if(newName){
          this.meadDepId = newName;
          this.departmentMedicalSort();
        }
      },
      deep: true,
    },
  },
  created() {
    this.departmentMedicalSort();
  },
  methods: {
    // 列表 分页
    async departmentMedicalSort() {
      let data = {
        departmentId: this.meadDepId, //科室id
        pageNum: this.pageNum, //页数
        pageSize: this.pageSize, //每页显示条数
      };
      let { data: res } = await this.$axios.departmentMedicalSort(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.tableData = res.rows;
        this.total = res.total;
      }
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val;
      // console.log(`当前页: ${val}`);
      this.departmentMedicalSort();
    },
    addMeadSub(){
      this.AdddialogVisible=true
    },
    AdddialogVisibleClose(){
      this.$refs.AddDateRef.resetFields();
      this.AdddialogVisible=false
      this.medTit='添加医嘱小类'
      this.titBtn='添加'
      this.AddDateModel={
        name:'',
        score:''
      }
    },
    addMead() {
      this.$refs.AddDateRef.validate((valid) => {
        if (valid) {
          this.addDepClick = false;
          this.addDepClickKing = true;

          if(this.titBtn=='修改'){
            let data = _qs.stringify({
              name: this.AddDateModel.name,
              score:this.AddDateModel.score,
              id:this.updId,
              departmentId:this.meadDepId
            });
            this.updateMedical(data)
          }else if(this.titBtn=='添加'){
            let data = _qs.stringify({
              name: this.AddDateModel.name,
              score: this.AddDateModel.score,
              departmentId:this.meadDepId
            });
            this.addMedical(data)
          }
        }
      });
    },
    async addMedical(data){
      let {data:res} = await this.$axios.addMedical(data);
      // console.log(res);
      this.addDepClick = true;
      this.addDepClickKing = false;
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.departmentMedicalSort();
        this.AdddialogVisibleClose()
      }else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    async updateMedical(data){
      let {data:res} = await this.$axios.updateMedical(data);
      // console.log(res);
      this.addDepClick = true;
      this.addDepClickKing = false;
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.departmentMedicalSort();
        this.AdddialogVisibleClose()
      }else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    async editMead(row){
      this.medTit='修改医嘱小类'
      this.titBtn='修改'
      this.AdddialogVisible=true
      // console.log(row)
      this.updId=row.id
      this.AddDateModel.name=row.name
      this.AddDateModel.score=row.score
    },
    async deleteMead(row){
      const confirmResult = await this.$confirm("确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);

      if (confirmResult !== "confirm") {
        return this.$message.info("已经取消删除");
      }
      let data = _qs.stringify({
        id: row.id,
        departmentId:this.meadDepId
      });
      let { data: res } = await this.$axios.deleMedical(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: "success",
        });
      this.departmentMedicalSort()
      }else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
  },
};
</script>
<style scoped lang="less">
.depTitle {
  display: flex;
  margin: 0 auto;
  margin-bottom: 20px;
}

.hosrow {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

/deep/ .depar-dia .el-form-item__content {
  margin-left: 0px !important;
}

.btnCen {
  .el-button {
    width: 150px;
    height: 40px;
  }
}

.depar-dia {
  width: 100%;
}

.el-pagination {
  margin-top: 20px;
}
</style>